import React, { useEffect, useState } from "react";
import {
  MdSupportAgent,
  MdKeyboardArrowLeft,
  MdOutlineNotifications,
} from "react-icons/md";
import style from "./style.module.css";
import cn from "classnames";
import { useHistory } from "react-router-dom";
import { Button } from "antd";
import { routes } from "App";
import SystemConfiguration from '../../services/systemConfiguration'

export default function HeaderPageSticky({
  title,
  fillColor = false,
  textBack,
  hideBack,
  hideExtra = false,
  scrollToShow = false,
  extra = "contact",
}) {
  const history = useHistory();
  const [system, setsystem] = useState({});
  useEffect(() => {
    SystemConfiguration.systemConfigurationGetDetail().then((res)=>{
      const {data, isSuccess} = res
        if(isSuccess){
          setsystem(data)
        }
      }
    )

  }, [])
  function handleScroll() {
    const headerSection = document.getElementById("header-section");
    if (!headerSection) return;
    if (window.scrollY > 150) {
      headerSection.classList.add(style["sticky"]);
    } else {
      headerSection.classList.remove(style["sticky"]);
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => document.body.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <nav
      id="header-section"
      style={{ background: scrollToShow && "transparent" }}
      className={cn(
        "d-flex align-items-center justify-content-between",
        style.header__container,
        {
          [style.header__container__fill]: fillColor,
        }
      )}
    >
      <div>
        {!hideBack && (
          <Button
            style={{ color: "currentcolor" }}
            icon={!textBack && <MdKeyboardArrowLeft size={25} />}
            onClick={() => history.goBack()}
            type="text"
          >
            {textBack}
          </Button>
        )}
      </div>

      <h1 style={{ color: "currentcolor", margin: 0 }}>{title}</h1>

      <div>
        {!hideExtra &&
          {
            contact:  (
              <Button
                onClick={() =>  history.push(routes.chat.path)}
                type="text"
                icon={<MdSupportAgent color={fillColor || "#fff"} size={25} />}
              />
            ),
            notify: (
              <Button
                onClick={() => history.push(routes.notification.path)}
                type="text"
                icon={<MdOutlineNotifications color={fillColor && "#fff"} size={25} />}
              />
            ),
          }[extra]}
      </div>
    </nav>
  );
}
