import "./index.scss";
import { useKeenSlider } from "keen-slider/react";
import React from "react";

export default function Header() {
  const [sliderRef] = useKeenSlider(
    {
      loop: true,
      slides: {
        perView: 1,
        spacing: 20
      },
    },
    [
      (slider) => {
        let timeout;
        let mouseOver = false;
        function clearNextTimeout() {
          clearTimeout(timeout);
        }
        function nextTimeout() {
          clearTimeout(timeout);
          if (mouseOver) return;
          timeout = setTimeout(() => {
            slider.next();
          }, 2000);
        }
        slider.on("created", () => {
          slider.container.addEventListener("mouseover", () => {
            mouseOver = true;
            clearNextTimeout();
          });
          slider.container.addEventListener("mouseout", () => {
            mouseOver = false;
            nextTimeout();
          });
          nextTimeout();
        });
        slider.on("dragStarted", clearNextTimeout);
        slider.on("animationEnded", nextTimeout);
        slider.on("updated", nextTimeout);
      },
    ]
  );

  const listBanner = ["/assets/images/new/Banner/1.jpeg", "/assets/images/new/Banner/2.jpeg","/assets/images/new/Banner/3.jpeg","/assets/images/new/Banner/4.jpeg",];

  return (
    <div className="header__navbar">
      <div ref={sliderRef} className="keen-slider h-100">
        {listBanner?.map((img, ind) => (
            <img key={img+ind} src={img} className="keen-slider__slide" alt="" />
        ))}
      </div>
    </div>
  );
}
