import React, { useEffect } from "react";
import { connect } from "react-redux";
import "./index.scss";
import MenuFooter from "../../components/MenuFooter";
import ModalIntroduceNewbie from "components/Modal/IntroduceNewbie";
import classNames from "classnames";
import BuddleChat from "components/BuddleChat";

function LayoutPage(props) {
  const {
    Component,
    className = "",
    isHiddenFooter,
    location,
    history,
    isFullWidth,
    member,
  } = props;
  const { isUserLoggedIn } = member;
console.log('propsprops', props);
  // useEffect(() => {
  //   if (props.isAuth) {
  //     // document.getElementById('root').style.height = 'calc(100vh - 120px)'
  //   } else {
  //     // document.getElementById("root").style.height = "100vh";
  //   }
  // }, [props, props.isAuth]);

  useEffect(() => {
    const bodyId = document.getElementById("body-root");
    if (bodyId) {
      // bodyId.className = "bg-gray"
      bodyId.classList.add(className || "");
    }
  }, [className]);

  return (
    <>
      <div style={{ marginBottom: !isHiddenFooter && 90 }}>
        <ModalIntroduceNewbie />
        {/* <BuddleChat /> */}
        <div
          className={classNames("mx-auto ", {
            layout__component: !isFullWidth,
          })}
        >
          <Component {...props} />
        </div>
      </div>
      {!isHiddenFooter && isUserLoggedIn ? (
        <MenuFooter location={location} history={history} />
      ) : null}
    </>
  );
}

const mapStateToProps = (state) => ({
  member: state.member || {},
});

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(LayoutPage);
