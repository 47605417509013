export const FETCH_APPS_REQUEST = "FETCH_APPS_REQUEST";
export const FETCH_APPS_SUCCESS = "FETCH_APPS_SUCCESS";
export const FETCH_APP_CHANGE = "FETCH_APP_CHANGE";
export const FETCH_APP_CONFIGURATION_REQUEST =
  "FETCH_APP_CONFIGURATION_REQUEST";
export const FETCH_APP_CONFIGURATION_SUCCESS =
  "FETCH_APP_CONFIGURATION_SUCCESS";

export const BANKS_OPTIONS = [
  { value: "Vietcom Bank", label: "Vietcom Bank" },
  { value: "Exim Bank", label: "Exim Bank" },
  { value: "Vietin Bank", label: "Vietin Bank" },
  { value: "SacomBank", label: "SacomBank" },
  {
    value: "Vietnam Prosperity Joint-Stock Commercial Bank",
    label: "Vietnam Prosperity Joint-Stock Commercial Bank",
  },
  { value: "Techcom", label: "Techcom" },
  { value: "BIDV Bank", label: "BIDV Bank" },
  { value: "MB Bank", label: "MB Bank" },
  { value: "KienLong Bank", label: "KienLong Bank" },
  { value: "HD Bank", label: "HD Bank" },
  { value: "SHB Bank", label: "SHB Bank" },
  { value: "SCB Bank", label: "SCB Bank" },
  { value: "ACB Bank", label: "ACB Bank" },
  { value: "AB Bank", label: "AB Bank" },
  { value: "Agri Bank", label: "Agri Bank" },
  { value: "Bac A Bank", label: "Bac A Bank" },
  { value: "BaoViet Bank", label: "BaoViet Bank" },
  { value: "DONGA Bank", label: "DONGA Bank" },
  { value: "GP Bank", label: "GP Bank" },
  { value: "INDOVINA Bank", label: "INDOVINA Bank" },
  { value: "LienViet Post Bank", label: "LienViet Post Bank" },
  { value: "Maritime", label: "Maritime" },
  { value: "Nam A Bank", label: "Nam A Bank" },
  { value: "Navi Bank", label: "Navi Bank" },
  { value: "NCB", label: "NCB" },
  { value: "OCB (PHUONG DONG)", label: "OCB (PHUONG DONG)" },
  { value: "PG Bank", label: "PG Bank" },
  { value: "PVCOM Bank", label: "PVCOM Bank" },
  { value: "SaiGon Bank", label: "SaiGon Bank" },
  { value: "SaA Bank", label: "SaA Bank" },
  { value: "ShinHan Bank", label: "ShinHan Bank" },
  { value: "Tien Phong Bank", label: "Tien Phong Bank" },
  { value: "United Overseas Bank", label: "United Overseas Bank" },
  { value: "VIB Bank", label: "VIB Bank" },
  { label: "VietABank", value: "VietABank" },
  { label: "VPBANK", value: "VPBANK" },
];


export const CHANNELS = [

  {
    label:  "Shopee",
    value: 1,
    img: '/assets/images/new/shopee.png'
  },
  {
    label:  "Lazada",
    value: 2,
    img: '/assets/images/new/laz.png'
  },
  {
    label:  "Tiki",
    value: 3,
    img: '/assets/images/new/tiki.png'
  },
  {
    label:  "Sendo",
    value: 4,
    img: '/assets/images/new/sendo.png'
  },
  {
    label:  "Amazon",
    value: 5,
    img: '/assets/images/new/amz.png'
  },
  {
    label:  "ZALORA",
    value: 6,
    img: '/assets/images/new/zalora.png'
  }
];

export const ORDER_STATUS = [
  {
    value: 'ORDER_PLACED_NEW',
    label: 'ORDER_PLACED_NEW'
  },
  {
    value: 'ORDER_PLACED_PROCESSING',
    label: 'ORDER_PLACED_PROCESSING'
  },
  {
    value: 'ORDER_PLACED_COMPLETED',
    label: 'ORDER_PLACED_COMPLETED'
  },
  {
    value: 'ORDER_PLACED_FAILED',
    label: 'ORDER_PLACED_FAILED'
  },
  {
    value: 'ORDER_PLACED_CANCELED',
    label: 'ORDER_PLACED_CANCELED'
  },
  {
    value: 'ORDER_PLACED_PENDING',
    label: 'ORDER_PLACED_PENDING'
  },
  {
    value: 'ORDER_RESELL_NEW',
    label: 'ORDER_RESELL_NEW'
  },
  {
    value: 'ORDER_RESELL_PROCESSING',
    label: 'ORDER_RESELL_PROCESSING'
  },
  {
    value: 'ORDER_RESELL_COMPLETED',
    label: 'ORDER_RESELL_COMPLETED'
  },
  {
    value: 'ORDER_RESELL_FAILED',
    label: 'ORDER_RESELL_FAILED'
  },
  {
    value: 'ORDER_RESELL_CANCELED',
    label: 'ORDER_RESELL_CANCELED'
  },
  {
    value: 'ORDER_RESELL_PENDING',
    label: 'ORDER_RESELL_PENDING'
  },
]