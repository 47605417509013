import Request from './request'

export default class AppUsers {

  static async getDetailUserById(data = {}) {
    return new Promise(resolve => {
      Request.send({
        method: 'POST',
        path: 'AppUsers/getDetailUserById',
        data
      }).then((result = {}) => {
        const { statusCode, data, message } = result

        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message })
        }
      })
    })
  }

  static async userPlaceOrder(data) {
    return new Promise(resolve => {
      Request.send({
        method: 'POST',
        path: 'ProductOrder/user/userPlaceDum',
        data
      }).then((result = {}) => {
        const { statusCode, data, message } = result
        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message })
        }
      })
    })
  }


  static async resellOrder(data) {
    return new Promise(resolve => {
      Request.send({
        method: 'POST',
        path: 'ProductOrder/user/placeResellOrder',
        data
      }).then((result = {}) => {
        const { statusCode, data, message, error } = result
        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message, error })
        }
      })
    })
  }

  static async userCheckoutOrder(data) {
    return new Promise(resolve => {
      Request.send({
        method: 'POST',
        path: 'ProductOrder/user/userCheckoutOrder',
        data
      }).then((result = {}) => {
        const { statusCode, data, message, error } = result
        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message, error })
        }
      })
    })
  }

  static async userGetProducts(data) {
    return new Promise(resolve => {
      Request.send({
        method: 'POST',
        path: 'Product/user/getList',
        data
      }).then((result = {}) => {
        const { statusCode, data, message } = result
        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message })
        }
      })
    })
  }

  static async changePasswordUser(data = {}) {
    return new Promise(resolve => {
      Request.send({
        method: 'POST',
        path: 'AppUsers/changePasswordUser',
        data
      }).then((result = {}) => {
        const { statusCode, data, message } = result

        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message })
        }
      })
    })
  }
  static async changeSecondPasswordUser(data = {}) {
    return new Promise(resolve => {
      Request.send({
        method: 'POST',
        path: 'AppUsers/user/changeSecondaryPassword',
        data
      }).then((result = {}) => {
        const { statusCode, data, message } = result

        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message })
        }
      })
    })
  }

  static async uploadImage(params) {
    return new Promise((resolve, reject) => {
      Request.send({
        method: "POST",
        path: "Upload/uploadMediaFile",
        data: params
      }).then(result => {
        const { statusCode, data, message } = result
        if (statusCode === 200) {
          resolve(data)
        } else {
          throw new Error(message);
        }
      }).catch(error => {
        reject(error);
      })
    })
  }

  static async updateInfoUser(data = {}) {
    return new Promise(resolve => {
      Request.send({
        method: 'POST',
        path: 'AppUsers/updateInfoUser',
        data
      }).then((result = {}) => {
        const { statusCode, data, message } = result

        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message })
        }
      })
    })
  }

  static async requestVerifyKYC(data = {}) {
    return new Promise(resolve => {
      Request.send({
        method: 'POST',
        path: 'AppUsers/user/submitIdentity',
        data
      }).then((result = {}) => {
        const { statusCode, data, message } = result

        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message })
        }
      })
    })
  }
  static async createConversationWithAdmin(data = {}) {
    return new Promise(resolve => {
      Request.send({
        method: 'POST',
        path: 'AppUserConversation/user/createNewConversationWithAdmin',
        data
      }).then((result = {}) => {
        const { statusCode, data, message } = result
        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message })
        }
      })
    })
  }
  static async getConversation(data = {}) {
    return new Promise(resolve => {
      Request.send({
        method: 'POST',
        path: 'AppUserChatLog/user/getList',
        data
      }).then((result = {}) => {
        const { statusCode, data, message } = result
        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message })
        }
      })
    })
  }
  static async sendMessageToAdmin(data = {}) {
    return new Promise(resolve => {
      Request.send({
        method: 'POST',
        path: 'AppUserChatLog/user/sendNewMessageToAdmin',
        data
      }).then((result = {}) => {
        const { statusCode, data, message } = result
        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message })
        }
      })
    })
  }

  static async registEvent(data = {}) {
    return new Promise(resolve => {
      Request.send({
        method: 'POST',
        path: 'AppUserEventRecord/user/registerEvent',
        data
      }).then((result = {}) => {
        const { statusCode, data, message } = result
        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message })
        }
      })
    })
  }


}